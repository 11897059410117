<template>
  <div class="realization">
    <MSDialog @close="MSimg.open = false" :isOpen="MSimg.open">
      <img id="MSimg" :src="MSimg.src" :alt="MSimg.alt" :title="MSimg.title"/>
    </MSDialog>
    <div class="container">
      <h1>Nos réalisations : </h1>
      <Article @openimg="fsi" v-for="article, i in Articles" :key="i" :mode="((i+3)%3)+1" :article="article"/>
      <h2 class="color-primary">Divers agencements :</h2>
      <p class="color-primary">Un agencement particulier, une solution adaptée !</p>
      <mosaique :images="divers"/>
      <router-link class="cta1" to="/Company">Apprenez à mieux nous connaître</router-link>
    </div>
  </div>
</template>

<script>
import Article from "@/components/Article.vue";
import MSDialog from "@/components/MSDialog.vue";
import realizations from "@/data/realizations.json";
import Mosaique from '../components/Mosaique.vue';
export default {
  name: 'Realization',
  components:{Article,MSDialog,Mosaique},
  data(){
    return {
      MSimg:{
        src:"",title:"",alt:"",open:false
      },
      Articles: realizations.Articles,
      divers:[
        {src:"https://www.nmagencement.fr/images/Realizations/divers-amenagements/2.jpg",title:"Claustras dans un stand d’exposition",alt:"L’agencement d’un stand d’exposition : 2 claustras, encadrement métallique noir, barres inclinées bois clair, l’ensemble posé sur un meuble noir."},
        {src:"https://www.nmagencement.fr/images/Realizations/divers-amenagements/43.jpg",title:"Un rêve de nature dans un château",alt:"Création de banquettes rondes inclinées dans un château ainsi que l’ossature arrondie permettant la fixation du tissus ajourés. "},
        {src:"https://www.nmagencement.fr/images/Realizations/divers-amenagements/IMG_3347.jpg",title:"Aménagement salle à manger",alt:"Meuble blanc sans poignées comprenant six portes et une colonne centrale ton bois avec trois tiroirs, sur lequel repose un plateau de ton bois."},
        {src:"https://www.nmagencement.fr/images/Realizations/divers-amenagements/IMG_3690.jpg",title:"Banquettes dans une bibliothèque universitaire",alt:"Banquettes en tissu reposant sur socle noir et bois "},
        {src:"https://www.nmagencement.fr/images/Realizations/divers-amenagements/IMG_3691.jpg",title:"Banquettes dans une bibliothèque universitaire 2",alt:"Banquettes en tissu reposant sur socle noir et bois bis"},
        {src:"https://www.nmagencement.fr/images/Realizations/divers-amenagements/IMG_7647.jpg",title:"Mobilier de séparation d’un salon",alt:"Agencement d’un mobilier d’angle sortant, du sol au plafond, parties basse et haute blanche, partie principale entre les deux en plaquage bois avec tablettes verre en angle, l’ensemble sans poignée"},
        {src:"https://www.nmagencement.fr/images/Realizations/divers-amenagements/IMG_7992.jpg",title:"Coworking DLJ",alt:"Agencement de 2 parties coworking ton bois, comprenant chacune, une table, 2 banquettes et une charpente décorative."},
        {src:"https://www.nmagencement.fr/images/Realizations/divers-amenagements/IMG_8004.jpg",title:"Banque d’accueil",alt:"Agencement d’une banque d’accueil blanche avec un plateau PMR en angle ton bois."},
        {src:"https://www.nmagencement.fr/images/Realizations/divers-amenagements/P1040772.jpg",title:"Mobilier pour restauration rapide",alt:"Agencement d’une ligne de vente restauration en lambris sapin et plateau blanc. Une banquette bois, un meuble vaisselle en bois et deux mange debout blanc."},
        {src:"https://www.nmagencement.fr/images/Realizations/divers-amenagements/im01.jpg",title:"Aménagement d’un grenier",alt:"L’agencement d’un grenier comprenant : Un placard de rangement 5 portes toute hauteur, avec habillage des rampants. L’ensemble en 3 plis épicéa brut et le sol en OSB"},
        {
          src:"https://www.nmagencement.fr/images/Home/01boulangeriecherbourg.jpg",
          title:"Boulangerie Petit Jean - Cherbourg", 
          alt:"L’agencement d’une boulangerie pâtisserie avec son décor planche bois brulé et ses murs de pierre rustique."
        },
        {
          src:"https://www.nmagencement.fr/images/Home/02Agencementboulangerienormandie.jpg",
          title:"Boulangerie Aux Plaisirs gourmands - Eure", 
          alt:"L’agencement d’une boulangerie avec son comptoir blanc comprenant une niche rétro éclairée par led et une panetière fer forgé posée devant un mur de pierre gris."
        },
        {
          src:"https://www.nmagencement.fr/images/Home/03patisseriereputee.jpg",
          title:"Pâtisserie Le Daniel Agencement – Rennes ", 
          alt:"L’agencement d’un comptoir pâtisserie blanc rétro éclairé en plinthe, l’ensemble posé devant un mur de pierre et sur un carrelage imitation parquet."
        },
        {
          src:"https://www.nmagencement.fr/images/Home/04Patisseriemisenvaleur.jpg",
          title:"Pâtisserie Le Daniel Entremets – Rennes", 
          alt:"On retrouve la chaleur d’un comptoir pâtisserie blanc éclairé par des suspensions blanches, tout cela posé devant un très joli mur de pierre."
        },
        {
          src:"https://www.nmagencement.fr/images/Home/06Agencementboulangeriedelamer.jpg",
          title:"Boulangerie La maison du pain – Saint Malo", 
          alt:"L’agencement d’une boulangerie pâtisserie avec son décor bois lambris clair, panetière fer forgé noir, ainsi que la présentation du comptoir et sa structure noire"
        },
        {
          src:"https://www.nmagencement.fr/images/Home/08patisserieduMOF.jpg",
          title:"Pâtisserie îlot Centre commercial Le Daniel - Rennes", 
          alt:"L’agencement d’un ilot dans un centre commercial, comprenant dans son pourtour un comptoir pâtisserie chocolat, décor soubassement bois surmonté d’une exposition blanche pour la présentation"
        },
        {
          src:"https://www.nmagencement.fr/images/Home/IMG_2344.jpg",
          title:"Pâtisserie chocolatier Ozanne Saint Brieuc", 
          alt:"Magasin agencé d’une vitrine chocolat murale, décor bois, présentation blanche surmontée de demi ponts coulissants en verre"
        },
        {
          src:"https://www.nmagencement.fr/images/Home/IMG_2469.jpg",
          title:"Chocolatier Rennes", 
          alt:"L’agencement d’une vitrine chocolat épurée avec son décor bas moitié bois, surmonté de blanc."
        },
        {
          src:"https://www.nmagencement.fr/images/Home/IMG_5261.jpg",
          title:"Agencement caisse automatique - Cergy Pontoise", 
          alt:"Agencement d’un meuble caisse boulangerie pâtisserie, couleur bois, filet orange, ensuite blanc et dessus compact noir roche avec l’intégration de caisse automatique glory"
        },
        {
          src:"https://www.nmagencement.fr/images/Home/IMG_8777.jpg",
          title:"Boulangerie pâtisserie Les trois cerises - Saint Brice sous forêt", 
          alt:"Agencement d’un comptoir boulangerie, décor noir roche et stratifié marbre blanc, et à l’arrière panetière fer forgé blanche sur mur noir"
        },
        {
          src:"https://www.nmagencement.fr/images/Home/IMG_1761.jpg",
          title:"Boulangerie La fournée d’Ines - Marseille", 
          alt:"Agencement comptoir boulangerie pâtisserie, plinthe grise rétro éclairée, surmonté d’un décor plaquettes de bois tranchées et dessus noir roc"
        }
      ]
    }
  },
  methods: {
    fsi(image){
      this.MSimg.src = image.src;
      this.MSimg.alt = image.alt;
      this.MSimg.title = image.title;
      this.MSimg.open = true;
    }
  }
  
}
</script>