<template>
    <div class="images">
        <MSDialog @close="MSimg.open = false" :isOpen="MSimg.open">
            <img :src="MSimg.src" :alt="MSimg.alt" :title="MSimg.title"/>
        </MSDialog>
        <div class="image" v-for="image, i in images" :key="i" :data-aos="RAos()">
        <img v-if="image.src != 'none'"  :src="image.src" :alt="image.alt" :title="image.title" @click="fsi(image)"/>
        <div v-else :data-aos="RAos()">&nbsp;</div>
        </div>
    </div>
</template>

<style scoped>
.images{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 25vh;
  margin-bottom: 30px;
}
.image{
  overflow: hidden;
}
.image>*{
  width:100%;
}
img:not(#MSimg){
  cursor: pointer;
}
@media only screen and (max-width: 400px){
    .images{
        grid-auto-rows: 10vh;
    }
}
</style>
<script>
import MSDialog from "@/components/MSDialog.vue";

export default {
  name: 'Mosaique',
  components:{
    MSDialog
  },
  props:{
      images: {
          type:Array,
          required:true,
      }
  },
  data(){
    return {
      MSimg:{
        src:"",title:"",alt:"",open:false
      },
      AosArray:["fade-down-right","fade-down-left","fade-up-right","fade-up-left","fade-right","fade-left","flip-right","flip-left","flip-up","flip-down","zoom-in","zoom-out"],
      
    }
  },
  methods:{
    Aos(type){
      //console.log(type);
      let rtrn  = this.AosArray[type-1];
      return !this.MSimg.open ? rtrn:"";
    },
    RAos(){
      return this.Aos(Math.floor(Math.random() * 12)+1);
    },
    fsi(image){
      this.MSimg.src = image.src;
      this.MSimg.alt = image.alt;
      this.MSimg.title = image.title;
      this.MSimg.open = true;
    }
  },
  
}
</script>
