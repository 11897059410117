<template>
    
    <v-card
        id="article"
        elevation="10"
        outlined
        dark
        v-bind:class="{mode1: SMode == 1, mode2: SMode == 2, mode3: SMode == 3,}"
      >
      
        <div :data-aos="AosText" class="text">

          <h2>{{articleOk.headline}}</h2>
          <p>{{articleOk.body}}</p>

        </div>

        <div v-for="image, i in articleOk.images" :key="i">
          <img v-if="image.src != ''" :data-aos="Aos(i+1)" :src="image.src" :title="image.title" :alt="image.title" @click="fsi(image)"/>
        </div>
      </v-card>
</template>
<style scoped>
img{
  cursor: pointer;
}

#article{
  display: grid;
  gap: 20px;
  max-width: 100%;
  padding:20px;
  margin: 20px;
}
.mode1,
.mode2{
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 173px 173px 173px;
}
.mode3{
  grid-template-columns: 20fr 320fr 35fr 135fr 100fr;
  grid-template-rows: 30px 148px 110px 74px 147px;
}
#article>*{
  overflow: hidden;
}
.mode1>*:nth-child(1),
.mode2>*:nth-child(1){
  grid-column: 2 / span 2;
  
}
.mode3>*:nth-child(1){
  grid-row: 5;
  grid-column: 1 / span 2;
}
.mode1>*>img,
.mode2>*>img,
.mode3>*>img{
  width:100%;
}
.mode1>*:nth-child(2),
.mode2>*:nth-child(2){
  grid-column: 1;
  grid-row: 1;
  
}
.mode3>*:nth-child(2){
  grid-column: 2;
  grid-row: 2 / span 2;
}
.mode1>*:nth-child(3){
  grid-column: 2;
  grid-row: 2 / span 2;
  
}
.mode2>*:nth-child(3){
  grid-column: 2;
  grid-row: 2;
  
}
.mode3>*:nth-child(3){
  grid-column: 4 / span 2;
  grid-row: 1 / span 2;
}
.mode1>*:nth-child(3)>img,
.mode2>*:nth-child(4)>img{
  width:100%;
}
.mode2>*:nth-child(4){
    grid-column: 3;
    grid-row: 2 / span 2;
}
.mode1>*:nth-child(4){
  grid-column: 3;

}
.mode3>*:nth-child(4){
  grid-column: 3 / span 2;
  grid-row: 4 / span 2;
}
.mode1>*:nth-child(5),
.mode2>*:nth-child(5){
  grid-column: 1;
  grid-row: 3;  
}
.mode3>*:nth-child(5){
  grid-column: 5;
  grid-row: 4 / span 3;
}
@media only screen and (max-width: 812px){
  #article{
    gap: 5px;
    padding: 10px;
    margin: 10px;
  }
  #article.mode1,
  #article.mode2,
  #article.mode3{
    grid-template-columns: 1.25fr 0.75fr;
    grid-template-rows: 200px 173px 70px 70px;
  }
  #article>*:nth-child(1){
    grid-row: 1;
    grid-column: 1 / span 2;
  
  }
  #article>*:nth-child(2){
    grid-row: 2;
    grid-column: 1 / span 2;
  
  }
  #article>*:nth-child(3){
    grid-row: 3 / span 2;
    grid-column: 1;
  }
  #article>*>img{
    width:100%;
  }
  #article>*:nth-child(4){
    grid-row: 3;
    grid-column: 2;
  }
  #article>*:nth-child(5){
    grid-row: 4;
    grid-column: 2;
  }
}
</style>
<script>
export default{
    name: "Article",
    props:{
        mode:{
            type:Number,
            required: false
        },
        article: {
          type: Object,
          required: false
        }
    },
    computed:{
        SMode(){
            return this.mode != undefined ? this.mode : 1;
        },
        AosText(){
          return "fade-left";
        },
        Aos1(){
            let rtrn = "fade-right";
            return rtrn;
        },
        Aos2(){
            let rtrn = "fade-up";
            if(this.SMode == 2){
                rtrn = "zoom-in";
            }
            return rtrn;
        },
        Aos3(){
            let rtrn = "fade-left";
            return rtrn;
        },
        Aos4(){
            let rtrn = "fade-up-right";
            return rtrn;
        },
        articleOk(){
          let rtrn = this.article != undefined ? {
            headline: this.article.headline != undefined ? this.article.headline : "Heading",
            body: this.article.body != undefined ? this.article.body : "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod laborum quis excepturi incidunt quos, eum facilis temporibus cum officiis recusandae. Consequatur earum omnis in! Recusandae esse praesentium rerum atque aliquid.",
            images:this.article.images != undefined ? this.article.images : [
              {src:"@/assets/logo.png", title: "NMAgencement Logo"},
              {src:"@/assets/logo.png", title: "NMAgencement Logo"},
              {src:"@/assets/logo.png", title: "NMAgencement Logo"},
              {src:"@/assets/logo.png", title: "NMAgencement Logo"}
            ]
          }
          :
          {
            headline: "Heading",
            body: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod laborum quis excepturi incidunt quos, eum facilis temporibus cum officiis recusandae. Consequatur earum omnis in! Recusandae esse praesentium rerum atque aliquid.",
            images:[
              {src:"@/assets/logo.png", title: "NMAgencement Logo"},
              {src:"@/assets/logo.png", title: "NMAgencement Logo"},
              {src:"@/assets/logo.png", title: "NMAgencement Logo"},
              {src:"@/assets/logo.png", title: "NMAgencement Logo"}
            ]
          };
          return rtrn;
        }
    },
    methods: {
      Aos(type){
        let rtrn = "";
        if (type == 1){
          rtrn = this.Aos1;
        }else if (type == 2){
          rtrn = this.Aos2;
        }else if (type == 3){
          rtrn = this.Aos3;
        }else if (type == 4){
          rtrn = this.Aos4;
        }
        return rtrn;
      },
      fsi(image){
        this.$emit("openimg",image);
      }
    }
}
</script>