<template>
    <div class="container">
        <h1>Notre histoire :</h1>
        <div class="grid1">
            <p data-aos="fade-right">Installée à Vitré en Ille et Vilaine (35) NMAgencement est actuellement dirigée par Norbert MORIN.</p>
            <p data-aos="fade-right" class="color-primary ">Fort d’une expérience de plus de 35 ans, notre savoir faire nous permet d’étudier votre projet et de vous proposer une mise en valeur optimale de vos produits.</p>
            <img data-aos="fade-left" id="norbert1" src="https://www.nmagencement.fr/images/Company/Norbert1.jpg" alt="Norbert MORIN dirigeant de NMAgencement" title="Norbert MORIN dirigeant de NMAgencement">
            <p data-aos="fade-up" class="gsp2 ">NMAgencement propose une qualité de fabrication très soignée et adaptée à vos besoins.</p>
            <img src="https://www.nmagencement.fr/images/Company/IMG_5475.jpg" alt="Boulangerie Petit Jean">
            <img src="https://www.nmagencement.fr/images/Company/IMG_0241.jpg" alt="Boulangerie Saint Servan">
            <p data-aos="fade-right">NMAgencement est spécialisée dans l’agencement de surfaces de ventes des métiers de bouche : boulangerie, pâtisserie, chocolaterie… NMAgencement est à votre écoute pour vous proposer un agencement personnalisé. Nous concevons, réalisons les vitrines de votre boulangerie. Nous composons un agencement adapté à votre environnement et à votre clientèle.</p>
            <div class="grid2">
                <img data-aos="fade-up" src="https://www.nmagencement.fr/images/Company/IMG_1093.jpg" alt="Boulangerie Cyril Prime 1">
                <img data-aos="fade-left" class="norbert2" src="https://www.nmagencement.fr/images/Company/Norbert3.jpg" alt="Norbert MORIN dirigeant de NMAgencement debout">
                <img data-aos="fade-up" src="https://www.nmagencement.fr/images/Company/IMG_1187.jpg" alt="Boulangerie Cyril Prime 2">
                <img data-aos="fade-up" src="https://www.nmagencement.fr/images/Company/IMG_1750.jpg" alt="Boulangerie Finium">
            </div>
            <p class="gsp2">Un seul objectif : Séduire votre clientèle et développer votre chiffre d’affaires.</p>
            <router-link class="cta1 gsp2" to="/Contact">Contactez-nous ici</router-link>
        </div>
    </div>
</template>
<style scoped>
.grid1 *{
    font-size:150%;
}
.grid1{
    display:grid;
    gap: 10px;
    grid-template-columns: 50% 50%;
    grid-template-rows: 100px 140px 60px 350px 450px;
}
.grid1>img{
    height: 100%;
}
.grid2{
    display:grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 150px;
}
.gsp2{
    grid-column: 1 /  span 2;
}
#norbert1{
    width: unset;
    height: 100%;
    grid-column: 2;
    grid-row: 1 / span 2;
}
.norbert2{
    grid-column: 2;
    grid-row: 1 / span 3;
    height:100% !important;
}
.grid2>img{
    height: 150px;
}
@media only screen and (max-width: 812px){
    .grid1{
        display: inherit;
    }
    #norbert1, .grid1>img, .grid2>img{
        width:100%;
        height:unset;
    }
    .grid2{
        grid-template-columns: 1fr;
        grid-auto-rows: auto;
    }
    .norbert2{
        width: 100%;
        grid-column: 1;
        grid-row: 4;
        height:unset;
    }

}
</style>
<script>
export default {
    name:"Company",
}
</script>