<template>
    <div id="MSDialog" v-bind:style="{'--display-component': isOpenCss}">
        <div class="head">
            <div id="close" title="Fermer" @click="close">
                <div></div>
                <div></div>
            </div>
        </div>
        <div class="body">
            <slot/>
        </div>
    </div>
</template>
<script>

export default ({
    name:"MSDialog",
    props: {
        isOpen:{
            type: Boolean,
            required: true,
        }
    },
    computed:{
        isOpenCss(){
            return this.isOpen ? 'block':'none';
        }
    },
    methods: {
        close(){
            this.$emit('close');
        }
    }
})
</script>

<style scoped>
#MSDialog {
    display: var(--display-component);
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    /* padding: 40px; */
    position: fixed;
    top: 0px;
    left: 0px;
    /* background-color: red; */

}
.head {
    background: rgba(var(--BG-Black),1);
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: flex-end;
}
.body {
    height:calc(100% - 70px);
    background: rgba(var(--Primary),1);
    overflow: scroll;
    display: flex;
    justify-content: center;
}
.body>*{
    height: 100%;
    padding: 10px;
    border-radius: 15px;
}
#close {
    width: 70px;
    height: 70px;
    /* background-color: yellow; */
    cursor: pointer;
    margin-right: 10px;
}
#close>*{
    background-color: rgb(var(--Primary));
    width: 4px;
    height: 70px;
    position: relative;
    left: 35px;
    border-radius: 40px;
    transition: all 0.5s;
}
#close>*:first-child{
    transform: rotate(45deg);
}
#close>*:last-child{
    transform: rotate(-45deg);
    top:-70px;
}
#close:hover>*{
    background-color: rgb(var(--Secondary));
    border: 1px solid rgb(var(--Primary));
}
#close:hover>*:first-child{
    transform: rotate(405deg);
}
#close:hover>*:last-child{
    transform: rotate(315deg);
}

</style>