<template>
  <div class="home">
    <v-snackbar
      v-model="snackbar.visibility"
      :timeout="8000"
    >
      {{ snackbar.msg }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar.visibility = false"
        >
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
    <MSDialog @close="MSimg.open = false" :isOpen="MSimg.open">
      <img id="MSimg" :src="MSimg.src" :alt="MSimg.alt" :title="MSimg.title"/>
    </MSDialog>
    <div class="container">
      <h1>NMAgencement : l'excellence ou rien</h1>
      <div v-if="nts" class="homeGrid">
        <v-carousel
        cycle
        :interval="2500"
        hide-delimiter-background
        hide-delimiters
        :show-arrows="false"
        height="380"
        data-aos="fade-up">
          <v-carousel-item v-for="image in fourFirsrt" :key="image.src">
            <img style="width:100%;"  :src="image.src" :alt="image.alt" :title="image.title" @click="fsi(image)"/>
          </v-carousel-item>
        </v-carousel>
        <div>
          <p data-aos="fade-left" class="color-primary font-size-150 tjust">Fort d’une expérience de plus de 35 ans, notre savoir faire nous permet d’étudier votre projet et de vous proposer une mise en valeur optimale de vos produits.</p>
          <router-link data-aos="zoom-in" class="cta1" to="/Realizations">Découvrez <br> notre marque</router-link>
        </div>
      </div>
      <div v-else id="nocarousel">
        <div v-for="image, i in fourFirsrt" :key="i" :data-aos="Aos(i+1)">
          <img style="width:100%;"  :src="image.src" :alt="image.alt" :title="image.title" @click="fsi(image)"/>
        </div>
        <div style="grid-column: 1 / span 2; margin-bottom: 100px;">
          <p data-aos="fade-left" class="color-primary font-size-150 tjust">Fort d’une expérience de plus de 35 ans, notre savoir faire nous permet d’étudier votre projet et de vous proposer une mise en valeur optimale de vos produits.</p>
          <router-link data-aos="zoom-in" class="cta1" to="/Realizations">Découvrez notre marque</router-link>
        </div>
      </div>
      <Mosaique :images="withoutFourFirst"/>
    </div>
  </div>
</template>
<style scoped>
#nocarousel{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
}
img:not(#MSimg){
  cursor: pointer;
}
.homeGrid{
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 20px;
  margin-bottom: 100px;
}
</style>
<script>
import MSDialog from "@/components/MSDialog.vue";
import Mosaique from '../components/Mosaique.vue';

export default {
  name: 'Home',
  components:{
    MSDialog, 
    Mosaique
  },
  data(){
    return {
      snackbar:{visibility: false, msg:"Nous avons bien pris en compte votre demande. À très bientôt !"},
      MSimg:{
        src:"",title:"",alt:"",open:false
      },
      AosArray:["fade-down-right","fade-down-left","fade-up-right","fade-up-left","fade-right","fade-left","flip-right","flip-left","flip-up","flip-down","zoom-in","zoom-out"],
      images:[
        {
          src:"https://www.nmagencement.fr/images/Home/01boulangeriecherbourg.jpg",
          title:"Boulangerie Petit Jean - Cherbourg", 
          alt:"L’agencement d’une boulangerie pâtisserie avec son décor planche bois brulé et ses murs de pierre rustique."
        },
        {
          src:"https://www.nmagencement.fr/images/Home/02Agencementboulangerienormandie.jpg",
          title:"Boulangerie Aux Plaisirs gourmands - Eure", 
          alt:"L’agencement d’une boulangerie avec son comptoir blanc comprenant une niche rétro éclairée par led et une panetière fer forgé posée devant un mur de pierre gris."
        },
        {
          src:"https://www.nmagencement.fr/images/Home/IMG_5250.jpg",
          title:"Boulangerie Comptoir des Gourmets – Cergy Pontoise", 
          alt:"L’agencement d’une boulangerie imposante avec son décor comptoir bois et blanc séparé d’un filet orange, l’ensemble posé devant meubles muraux surmontés d’un décor carreau de métro."
        },
        {
          src:"https://www.nmagencement.fr/images/Home/03patisseriereputee.jpg",
          title:"Pâtisserie Le Daniel Agencement – Rennes ", 
          alt:"L’agencement d’un comptoir pâtisserie blanc rétro éclairé en plinthe, l’ensemble posé devant un mur de pierre et sur un carrelage imitation parquet."
        },
        {
          src:"https://www.nmagencement.fr/images/Home/04Patisseriemisenvaleur.jpg",
          title:"Pâtisserie Le Daniel Entremets – Rennes", 
          alt:"On retrouve la chaleur d’un comptoir pâtisserie blanc éclairé par des suspensions blanches, tout cela posé devant un très joli mur de pierre."
        },
        {
          src:"https://www.nmagencement.fr/images/Home/06Agencementboulangeriedelamer.jpg",
          title:"Boulangerie La maison du pain – Saint Malo", 
          alt:"L’agencement d’une boulangerie pâtisserie avec son décor bois lambris clair, panetière fer forgé noir, ainsi que la présentation du comptoir et sa structure noire"
        },
        {
          src:"https://www.nmagencement.fr/images/Home/08patisserieduMOF.jpg",
          title:"Pâtisserie îlot Centre commercial Le Daniel - Rennes", 
          alt:"L’agencement d’un ilot dans un centre commercial, comprenant dans son pourtour un comptoir pâtisserie chocolat, décor soubassement bois surmonté d’une exposition blanche pour la présentation"
        },
        {
          src:"https://www.nmagencement.fr/images/Home/IMG_2344.jpg",
          title:"Pâtisserie chocolatier Ozanne Saint Brieuc", 
          alt:"Magasin agencé d’une vitrine chocolat murale, décor bois, présentation blanche surmontée de demi ponts coulissants en verre"
        },
        {
          src:"https://www.nmagencement.fr/images/Home/IMG_2469.jpg",
          title:"Chocolatier Rennes", 
          alt:"L’agencement d’une vitrine chocolat épurée avec son décor bas moitié bois, surmonté de blanc."
        },
        {
          src:"https://www.nmagencement.fr/images/Home/IMG_5261.jpg",
          title:"Agencement caisse automatique - Cergy Pontoise", 
          alt:"Agencement d’un meuble caisse boulangerie pâtisserie, couleur bois, filet orange, ensuite blanc et dessus compact noir roche avec l’intégration de caisse automatique glory"
        },
        {
          src:"https://www.nmagencement.fr/images/Home/IMG_8777.jpg",
          title:"Boulangerie pâtisserie Les trois cerises - Saint Brice sous forêt", 
          alt:"Agencement d’un comptoir boulangerie, décor noir roche et stratifié marbre blanc, et à l’arrière panetière fer forgé blanche sur mur noir"
        },
        {
          src:"https://www.nmagencement.fr/images/Home/IMG_1761.jpg",
          title:"Boulangerie La fournée d’Ines - Marseille", 
          alt:"Agencement comptoir boulangerie pâtisserie, plinthe grise rétro éclairée, surmonté d’un décor plaquettes de bois tranchées et dessus noir roc"
        }
      ]
    }
  },
  methods:{
    Aos(type){
      //console.log(type);
      let rtrn  = this.AosArray[type-1];
      return !this.MSimg.open ? rtrn:"";
    },
    RAos(){
      return this.Aos(Math.floor(Math.random() * 12)+1);
    },
    fsi(image){
      this.MSimg.src = image.src;
      this.MSimg.alt = image.alt;
      this.MSimg.title = image.title;
      this.MSimg.open = true;
    }
  },
  computed:{
    nts(){
      return window.innerWidth > 1000;
    },
    fourFirsrt(){
      return [this.images[0],this.images[1],this.images[2],this.images[3]];
    },
    withoutFourFirst(){
      let rtrn = this.images.slice();
      let i = 0;
      while (i < 4) {
        rtrn.shift();
        i++;
      }
      rtrn = rtrn.concat(this.fourFirsrt);
      let clues = new Array();

      let j = 0;
      let max = rtrn.length * 0
      while (j < max){
        let ramdom = Math.floor(Math.random() * rtrn.length);
        while(clues.includes(ramdom)){
          ramdom = Math.floor(Math.random() * rtrn.length);
        }
        clues.push(ramdom)
        j++;
      }
      //console.log(clues);
      clues.forEach(index => {
        rtrn.splice(index, 0, {src:"none"});
      });
   
      return rtrn;
    },
  },
  mounted() {
    if(this.$route.query.form == "true"){
      this.snackbar.visibility = true;
    }
  },
  beforeCreate(){
    if(this.$route.query.page == "legal-mention"){
      this.$router.push('/legal-mention');
    } else if(this.$route.query.page == "Contact"){
      this.$router.push('/Contact');
    } else if(this.$route.query.page == "Realizations"){
      this.$router.push('/Realizations');
    } else if(this.$route.query.page == "Company"){
      this.$router.push('/Company');
    }
  }
}
</script>
