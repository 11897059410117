import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Realizations from '../views/Realizations.vue'
import Company from '../views/Company.vue'
import Legal from '../views/Legal.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Realizations',
    name: 'Realizations',
    component: Realizations
  },
  {
    path: '/Company',
    name: 'Company',
    component: Company
  },
  {
    path: '/Contact',
    name: 'Contact',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue')
  },
  {
    path: '/Legal-Mention',
    name: 'Legal Mention',
    component: Legal
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
